<template>
  <div
    v-if="type === 'file'"
    class="the-input__wrapper the-input__wrapper--file"
  >
    <label
      :for="inputId"
      class="the-input__label the-input__label--file"
    >
      <img class="the-input__clip-icon" src="@/assets/clip.svg" alt="Clip">

      {{ fileName || labelName }}

      <input
        class="the-input__input the-input__input--file"
        :id="inputId"
        :type="type"
        :required="required"
        @input="$emit('input', $event.target.value)"
        @change="previewFilesName"
      >
    </label>
    
    <button
      v-if="fileName"
      class="the-input__clear-file"
    />
  </div>

  <div class="the-input__wrapper" v-else>
    <label :for="inputId" class="the-input__label">{{ labelName }}</label>
    <input
      class="the-input__input"
      :id="inputId"
      :type="type"
      :placeholder="placeholder"
      :required="required"
      @input="$emit('input', $event.target.value)"
    >
  </div>
</template>

<script>
export default {
  name: 'TheInput',
  props: {
    inputId: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String
    },
    labelName: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    fileName: '',
  }),
  methods: {
    previewFilesName (event) {
      this.fileName = event.target.files?.[0]?.name
    },
  }
}
</script>