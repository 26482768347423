<template>
  <section class="registration">
    <div class="container registration__container">
      <div class="registration__text-content">
        <h3 class="registration__title">РЕЄСТРАЦІЯ НТЗ</h3>

        <p class="registration__text">
          Будь ласка, завчасно забезпечте Директора НТЗ електронним цифровим підписом та печаткою,
          які будуть потрібні для роботи в системі.
          <br>
          <br>
          Телефон підтримки: <a class="registration__link" href="tel:+380443347861">+38 044 334 78 61</a>
        </p>
      </div>

      <form class="registration__form">
        <div class="registration__inputs-wrapper">
          <TheInput
            inputId="layerName"
            labelName="Юридична назва НТЗ"
            placeholder="Тестовый тренажерний центр, ТОВ"
          />

          <TheInput
            inputId="layerName"
            labelName="ПІБ Директора"
            placeholder="Іванов Іван Іванович"
          />

          <TheInput
            inputId="directorEmail"
            type="email"
            labelName="Email Директора"
            placeholder="test@gmail.com"
          />

          <TheInput
            inputId="directorTel"
            labelName="Телефон Директора"
            type="tel"
            placeholder="+38096-111-11-11"
          />

          <TheInput
            inputId="nameNtzCertificate"
            labelName="Назва НТЗ для сертифікатів"
            placeholder="ТЕСТОВИЙ ТРЕНАЖЕРНИЙ ЦЕНТР"
          />

          <TheInput
            inputId="nameNtzCertificateEng"
            labelName="Назва НТЗ для сертифікатів eng"
            placeholder="TEST TRAINING CENTRE"
          />

          <TheInput
            inputId="contactInformation"
            labelName="Контактна інформація для сертифікатів"
            placeholder="Україна, 11111, м. Київ; вул. Вільна, 1; e-mail: info@test.academy; web: test.academy"
          />

          <TheInput
            inputId="contactInformationEng"
            labelName="Контактна інформація для сертифікатів eng"
            placeholder="1, Vilna str.; Kyiv, 11111, Ukraine; e-mail: info@test.academy; web: test.academy"
          />

          <TheInput
            inputId="telForCertification"
            labelName="Телефони для сертифікатів"
            type="tel"
            placeholder="38(048) 111-11-11; 38(011) 111-11-11"
          />

        </div>

        <div class="registration__inputs-wrapper">
          <div class="registration__input-file-group">
            <p class="registration__label">Підпис та печатка директора</p>

            <span class="registration__sub-label">
              Посилання для завантаження бланка для заповнення
              <a class="registration__link" href="#">тут</a>
            </span>

            <TheInput
              inputId="fileSignature"
              labelName="Завантажити файл підпису та печатки"
              type="file"
            />
          </div>

          <div class="registration__input-file-group">
            <p class="registration__label">Додайте діючі протоколи</p>

            <span class="registration__sub-label">
              Має бути у форматі .pdf
            </span>

            <TheInput
              inputId="fileSeal"
              labelName="Завантажити діючі протоколи"
              type="file"
            />
          </div>
        </div>

        <div class="registration__accept-rules">
          <label class="registration__rules" for="acceptCheckbox">
            <input
              class="registration__input"
              name="accept"
              id="acceptCheckbox"
              type="checkbox"
            />
            <span class="registration__box"></span>

            Я надаю
            <a class="registration__link" href="#">
              Згоду на обробку персональних даних
            </a>
            та підтверждую, що ознайомлений(-а) зі змістом
            <a class="registration__link" href="#">
              Політика конфіденційності з обробки та захисту персональних даних
            </a>
            та
            <a class="registration__link" href="#">
              Договором про надання доступу до автоматизованої реєстрації у єдиному Державному реєстрі документів
              моряків України.
            </a>
          </label>
        </div>

        <button class="registration__submit-btn">Зареєструвати</button>
      </form>
    </div>
  </section>
</template>

<script>
import TheInput from '@/components/TheInput'

export default {
  name: 'Registration',
  components: {
    TheInput
  },
}
</script>