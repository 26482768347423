<template>
  <section class="tariffs">
    <div class="container container--medium">
      <h2 class="tariffs__title">Для бизнеса</h2>

      <div class="tariffs__card-wrapper">
        <div class="tariffs__card-content">
          <div class="tariffs__header-card"/>

          <div class="tariffs__main-card">
            <ul class="tariffs__list tariffs__list--text">
              <li class="tariffs__item">Вход в личный кабинет с мобильного</li>
              <li class="tariffs__item">Автоматические результаты теста</li>
              <li class="tariffs__item">Онлайн сдача экзамена выходного контроля</li>
              <li class="tariffs__item">Расширенные фильтры по заявкам, сертификатам</li>
              <li class="tariffs__item">Возможность создавать помощников сервиса (Админ, Бухгалтер)</li>
              <li class="tariffs__item">Отчеты в XLS</li>
            </ul>
          </div>

          <div class="tariffs__footer-card"/>
        </div>

        <div class="tariffs__card-content">
          <div class="tariffs__header-card">
            <h3 class="tariffs__card-title">Базовый</h3>
          </div>

          <div class="tariffs__main-card">
            <ul class="tariffs__list">
              <li class="tariffs__item">
                <img src="@/assets/done.svg" alt="Include">
              </li>
              <li class="tariffs__item">
                <img src="@/assets/done.svg" alt="Include">
              </li>
              <li class="tariffs__item">
                <img src="@/assets/done.svg" alt="Include">
              </li>
              <li class="tariffs__item">
                <img src="@/assets/cross.svg" alt="Not Include">
              </li>
              <li class="tariffs__item">
                <img src="@/assets/cross.svg" alt="Not Include">
              </li>
              <li class="tariffs__item">
                <img src="@/assets/cross.svg" alt="Not Include">
              </li>
            </ul>
          </div>

          <div class="tariffs__footer-card">
            <button class="tariffs__btn">Обрати</button>
          </div>
        </div>

        <div class="tariffs__card-content tariffs__card-content--premium">
          <div class="tariffs__header-card">
            <h3 class="tariffs__card-title">Премиум</h3>
            <img
              class="tariffs__card-icon"
              src="@/assets/premium-star.svg"
              alt="Premium Star"
              width="33px"
              height="33px"
            >
          </div>

            <div class="tariffs__main-card">
            <ul class="tariffs__list">
              <li class="tariffs__item">
                <img src="@/assets/done-white.svg" alt="Include">
              </li>
              <li class="tariffs__item">
                <img src="@/assets/done-white.svg" alt="Include">
              </li>
              <li class="tariffs__item">
                <img src="@/assets/done-white.svg" alt="Include">
              </li>
              <li class="tariffs__item">
                <img src="@/assets/done-white.svg" alt="Include">
              </li>
              <li class="tariffs__item">
                <img src="@/assets/done-white.svg" alt="Include">
              </li>
              <li class="tariffs__item">
                <img src="@/assets/done-white.svg" alt="Include">
              </li>
            </ul>
          </div>

          <div class="tariffs__footer-card">
            <button class="tariffs__btn tariffs__btn--white">Обрати</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tariffs'
}
</script>